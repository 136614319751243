import app from "@/main";
import {MtsTebligatSafahatListRow} from "../../../uyap/MtsTebligatSafahatList";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {TaskDurum} from "../../../enum/TaskDurum";

export class MtsOdemeTaskResponse {
    dosyaEsasNo: string = ""
    icraDairesi: string = ""
    message: string = ""
    safahat: any[] = []
}

export class MtsOdemeTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let dosya = await app.$uyap.DosyaBul().run(task);
            const tebligatlar = (await app.$uyap.MtsTebligatList().run({dosyaId: dosya.dosyaId})).items;
            task.response = new MtsOdemeTaskResponse();
            task.response.dosyaEsasNo = dosya.dosyaNo;
            task.response.icraDairesi = dosya.birimAdi;
            let teslimEdildi: boolean = false;
            for (let tebligat of tebligatlar) {
                //const safahatler = await app.$uyap.MtsTebligatSafahatList().run({barkodNo: parseInt(tebligat.mersule)});
                //const teslimSafahati = safahatler.find((safahat: MtsTebligatSafahatListRow) => safahat.aciklama == "MAZBATA TESLİM");
                if (tebligat.TebligatSonuc == "Tebliğ Edildi") {
                    teslimEdildi = true;
                }
                // task.response.safahat = safahatler.map((safahat: MtsTebligatSafahatListRow) => {
                //     return [
                //         dosya.dosyaNo,
                //         tebligat.mersule,
                //         safahat.aciklama,
                //         safahat.islemYeri,
                //         safahat.tarih,
                //     ]
                // });
            }
            if (teslimEdildi) {
                const alacaklilar = await app.$uyap.MtsIcraAcilisAlacakliTaraflar().run({dosyaId: dosya.dosyaId});
                if (alacaklilar.taraflarList.length > 0) {
                    let alacakliKisiKurumId = alacaklilar.taraflarList[0].asilTarafIlkKisiKurumId
                    const mtsIcraTakipBaslatCevap = await app.$uyap.MtsIcraTakipBaslat().run({
                        alacakliKisiKurumId: alacakliKisiKurumId,
                        dosyaId: dosya.dosyaId,
                        icraTakipSebepId: task.data.icraTakipSebep.id,
                        kotaTur: task.data.kotaTur
                    });
                    task.response.message = mtsIcraTakipBaslatCevap.message;
                    task.status = TaskDurum.BITTI_BASARILI;
                    return task;
                } else {
                    console.log("alacaklilar not found");
                    throw new Error("Alacaklı Bulunamadı.")
                }
            } else {
                task.response.message = "Teslim edilmiş tebligat bulunamadı.";
                throw new Error(task.response.message);
            }
        } catch (e) {
            console.log(e);
            throw new Error(e.message);
        }
    }
}