import {UyapRequest} from "./UyapRequest";
import {ISorguSonuc} from "./CommonInterfaces";
import app from "@/main";
import {BorcluTasinmazTakyidatSorgulamaCevap} from "@/plugins/uyap-plugin/uyap/BorcluTasinmazTakyidatSorgulama";

export interface BorcluTasinmazHisseSorgulamaTalep {
    dosyaId: string,
    kisiKurumId: number,
    tasinmazId: number
}

export interface BorcluTasinmazHisseSorgulamaCevap extends ISorguSonuc<Array<IBorcluTasinmazHisseSorgulamaSonuc> | string> {}

export interface IBorcluTasinmazHisseSorgulamaSonuc {
    hisseList: HisseList[]
    borcluBilgileri: BorcluBilgileri
    sonuc: boolean
    id: string
    isCompressed: boolean
    htmlHataKodList: any[]
    isMock: boolean
    takyidat: BorcluTasinmazTakyidatSorgulamaCevap
}

export interface HisseList {
    hisseId: number
    pay: string
    payda: string
    hisseTip: string
    istirakNo: number
    tapuBolumDurum: string
    malik: Malik
    terkinIslem: TerkinIslem
    tesisIslem: TesisIslem
    tasinmazId: number,
    // takyidat: BorcluTasinmazTakyidatSorgulamaCevap
}

export interface Malik {
    malikId: number
    malikTip: string
    malikOzetBilgi: string
}

export interface TerkinIslem {
    islemId: number
    islemDurum: string
}

export interface TesisIslem {
    islemId: number
    islemTanimAd: string
    baslamaSekilAd: string
    yevmiye: Yevmiye
    islemDurum: string
}

export interface Yevmiye {
    yevmiyeId: number
    kurum: string
    tarih: string
    yevmiyeNo: number
}

export interface BorcluBilgileri {
    kisiTumDVO: KisiTumDvo
    kisiKurumId: number
    turu: number
}

export interface KisiTumDvo {
    olumKaydi: boolean
    isAvukat: boolean
    isGecerlilikAktifAvukat: boolean
    isNoter: boolean
    isTarafUyari: boolean
    isHakimSavci: boolean
    mernisDegisiklikVarmi: boolean
    mernisDegisiklikNedeni: string
    sorguTuru: number
    tcKimlikNo: string
    adi: string
    soyadi: string
    babaAdi: string
    anaAdi: string
    dogumTarihiStr: string
    aktifMernisAdresiSorgusu: boolean
    hasOlumKaydi: boolean
}


export default class BorcluTasinmazHisseSorgulama extends UyapRequest<BorcluTasinmazHisseSorgulamaTalep, BorcluTasinmazHisseSorgulamaCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: true,
            ttl: (60 * 24), //* 1 gun
            key: "borcluTasinmazHisseSorgulama_" + app.$uyapHelper.hash(this.talepData)
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + "borclu_bilgileri_goruntule_tasinmaz_hisse_brd.ajx";
    }

    getMockResponse(): any {
        return {"hisseList":[{"hisseId":231802298,"pay":"153.000","payda":"54880.000","hisseTip":"Musterek","istirakNo":0,"tapuBolumDurum":"Aktif","malik":{"malikId":159221370,"malikTip":"GercekKisi","malikOzetBilgi":"(SN:159221370) NAZİFE ULCAY : CEMAL Kızı KN:10219424160"},"terkinIslem":{"islemId":0,"islemDurum":"BELIRSIZ"},"tesisIslem":{"islemId":78372986,"islemTanimAd":"Satış","baslamaSekilAd":"Malikin Talebiyle","yevmiye":{"yevmiyeId":58231441,"kurum":"Avcılar","tarih":"2013-02-11T15:14:07.66","yevmiyeNo":2710},"islemDurum":"ISLEM_TAMAMLANDI"},"tasinmazId":25842875}],"borcluBilgileri":{"kisiTumDVO":{"olumKaydi":false,"isAvukat":false,"isGecerlilikAktifAvukat":false,"isNoter":false,"isTarafUyari":false,"isHakimSavci":false,"mernisDegisiklikVarmi":false,"mernisDegisiklikNedeni":"","sorguTuru":0,"tcKimlikNo":"10219424160","adi":"NAZİFE","soyadi":"ULCAY","babaAdi":"CEMAL","anaAdi":"HÜSNİYE","dogumTarihiStr":"10/10/1977","aktifMernisAdresiSorgusu":true,"hasOlumKaydi":false},"kisiKurumId":40318081,"turu":0},"sonuc":false,"id":"6014713013221133362044644020441211003613546","isCompressed":false,"htmlHataKodList":[],"isMock":false};
    }

    async parse(data: BorcluTasinmazHisseSorgulamaCevap): Promise<BorcluTasinmazHisseSorgulamaCevap> {
        return data;
    }
}

