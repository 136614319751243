import {UyapRequest} from "./UyapRequest";

export interface MtsIcraTakipBaslatTalep {
    alacakliKisiKurumId: string,
    dosyaId: string | number,
    icraTakipSebepId: string,
    kotaTur: string
}

export interface MtsIcraTakipBaslatCevap {
    type: string,
    message: string,
    log: string
}

export default class MtsIcraTakipBaslat extends UyapRequest<MtsIcraTakipBaslatTalep, MtsIcraTakipBaslatCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return {
            "type": "success",
            "message": "Ankara Batı İcra Dairesi 2019/111TEST dosya no ile icra takip dosyanız oluşturulmuştur! Lütfen ilgili dosyaya evrak gönderme işlemini tamamlayınız!",
            "log": ""
        };
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mts_icra_takip_baslat_brd.ajx';
    }

    async parse(data: any): Promise<MtsIcraTakipBaslatCevap> {
        return data;
    }

}
