import {UyapRequest} from "./UyapRequest";
import {AdresRow} from "./CommonInterfaces";

export interface MtsTebligatListTalep {
    dosyaId: number | string
}

export class MtsTebligatListCevap {
    items: MtsTebligatListRow[] = [];
    message: string = '';
}

export interface MtsTebligatListRow {
    dosyaNoYil: number,
    dosyaSiraNo: number,
    dosyaNo: string,
    dosyaTurKod: number,
    TebligatStatu: number,
    TebligatSonuc: string,
    TebligatTuru: number,
    TebligatTipi: string,
    TebligatTipiAciklamasi: string,
    TebligatYapilanKisininAdiSoyadi: string,
    TebligatAdresi: Array<AdresRow>,
    TebligatTarihi: string,
    mersule: string,
    dagitimID: number,
    secili: boolean,
    raporId: number,
    tebligedilenAdi: string,
    tebligedilenSoyadi: string,
    tebligatSonucKayitId: number,
    tarafList: Array<any>,
    vekilTarafList: Array<any>,
    tahsilatList: Array<any>,
    adliYardimTalebi: number,
    gonderiTipi: number,
    masrafGgIdList: Array<any>,
    donusluTebligat: number,
    icraDairesiMi: boolean,
    idariYargiMi: boolean,
    hukukMu: boolean,
    vekilliktenAzledilenlereMi: boolean,
    onaySonrasiIcraMi: boolean
}

export class MtsTebligatList extends UyapRequest<MtsTebligatListTalep, MtsTebligatListCevap> {
    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 24), //* 1 gun
            key: "_"
        };
    }

    getMockResponse(): any {
        return [{"dosyaNoYil":2022,"dosyaSiraNo":1411918,"dosyaNo":"2022/1411918","dosyaTurKod":294,"TebligatStatu":1,"TebligatTuru":0,"tebligatTuruAciklamasi":"Kapalı Tebligat","TebligatTipi":"TBLTP00001","TebligatTipiAciklamasi":"Normal Tebligat","TebligatYapilanKisininAdiSoyadi":"BORÇLU VE MÜFLİS HAKAN DOLUPINAR","TebligatAdresi":{"ilKodu":22,"ilAdi":"EDİRNE","ilceAdi":"İPSALA","ilceKodu":4,"adres":"SARAÇ İLYAS MAH. 1 NOLU DEĞİRMEN SK. NO:36C/1 İÇ KAPI NO:11 ","kisiKurumID":42402515,"adresID":328003760,"adresTuru":"ADRTR00008","eklenebilirAdresMi":false,"kayitliAdresMi":false,"aktifAdresmi":false,"guncellendiMi":false},"TebligatTarihi":"Dec 16, 2022 9:33:15 PM","GGEvrakID":11142220631,"TebligatSonuc":"Tebliğ Edildi","postatebligatSekli":"TBLPT00001","isTebligEdildi":"E","tebligatIlanBilgileri":[],"KayitID":11030578315,"postayaVerildi":false,"tebligatYapilanKisiTGNLKAYITKisiKurumID":42402515,"dosyaID":"dGCxl1FqWGqxvlgJHrDTEMSnKDB7YWeuTRNt78t4vI7XTgHHbgCo7gOH5l9MjHZH","tebligatKayitTablosuKayitTarihi":"Dec 16, 2022 9:33:15 PM","tebligatKayitTablosuKayitTarihiFormatted":"16/12/2022 21:33","tebligTarihi":"Dec 22, 2022 12:00:00 AM","mersule":"4100080289596","dagitimID":2382501771,"secili":false,"raporId":11142220631,"tebligedilenSoyadi":"KEWNDISI","tebligatSonucKayitId":11095637828,"tarafList":[],"vekilTarafList":[],"tahsilatList":[],"adliYardimTalebi":1,"gonderiTipi":0,"masrafGgIdList":[],"donusluTebligat":0,"icraDairesiMi":false,"idariYargiMi":false,"hukukMu":false,"vekilliktenAzledilenlereMi":false,"onaySonrasiIcraMi":false,"kaydedildi":false}];
    }

    getUrl(): string {
        return this.ImzaClientAddr + 'mts_tebligat_list_brd.ajx';
    }

    async parse(data: any): Promise<MtsTebligatListCevap> {
        if(data){
            return {
                items: data,
                message: ''
            }
        }else{
            throw new Error("Tebligat listesi bulunamadı");
        }

    }

}

export default MtsTebligatList;
