import {UyapRequest} from "./UyapRequest";

export interface PttBarkodSorgulaTalep {
    q: string
}

export interface PttBarkodSorgulaCevap {
    message: string,
    status: "error"|"success",
    items: any[]
}

export default class PttBarkodSorgula extends UyapRequest<PttBarkodSorgulaTalep, PttBarkodSorgulaCevap> {
    delayTime = 250;

    getMethod(): 'GET' | 'POST' {
        return 'POST';
    }

    cache() {
        return {
            active: false,
            ttl: (60 * 1), //* 1 gun
            key: "barkodptt_" + this.talepData.q
        };
    }

    getMockResponse(): any {
        return {};
    }

    getUrl(): string {
        return "https://imza.eicrapro.com:6097/Track/summaryResult";
    }

    async parse(data: any): Promise<PttBarkodSorgulaCevap> {
        try{
            let div = document.createElement("div");
            let items: any[] = [];
            div.innerHTML = data;
            let alert = div.querySelector(".container .alert");
            if(alert){
                return  {
                    message : alert.innerHTML.replace("\n","").trim(),
                    items : [],
                    status : "error"
                }
            }
            //@ts-ignore
            let durum = div.querySelector("main .container .row .col-8 h8").innerText;
            let list = div.querySelectorAll("#shipmentGroup tbody tr")
            list.forEach(item=>{
                let tds= item.querySelectorAll("td");
                items.push({
                    tarih:tds[0].innerText,
                    islem:tds[1].innerText,
                    sube:tds[2].innerText,
                    il_ilce:tds[3].innerText,
                    aciklama:tds[4].innerText,
                })
            })
            return {
                message : durum,
                items : items,
                status : "success"
            };
        }catch (e) {
            return {
                message : e.message,
                items : [],
                status : "error"
            }
        }
    }

}
