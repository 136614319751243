import app from "@/main";
import {DosyaBorclu} from "../../../uyap/DosyaBorclu";
import {AbstractTaskHandler} from "./AbstractTaskHandler";
import {ITask} from "../GorevYoneticisiTypes";
import {UyapHacizEvrakTuru} from "../../../enum/uyap/evrak/UyapHacizEvrakTuru";
import TakipDosya from "../../../uyap/classes/TakipDosya";

export class TopluHacizHazirlaTaskCevap {
    dosya: TakipDosya;
    borclular: DosyaBorclu[] = [];
    params: IHacizParams;

    constructor(dosya: TakipDosya, params: IHacizParams) {
        this.dosya = dosya;
        this.params = params;
    }
}

export class TopluTalepGonderTaskCevap {
    dosya: TakipDosya;
    borclu: DosyaBorclu;
    params: IHacizParams;

    constructor(dosya: TakipDosya, params: IHacizParams, borclu: DosyaBorclu) {
        this.dosya = dosya;
        this.params = params;
        this.borclu = borclu;
    }
}

interface IHacizParams {
    hacizParams: any[],
    tebligatParams: any[],
    digerTalepParams: any[],
}

export class TopluHacizHazirlaTaskHandler extends AbstractTaskHandler {
    async handle(task: ITask): Promise<ITask> {
        try {
            let dosya = await app.$uyap.DosyaBul().run(task);
            let response: TopluHacizHazirlaTaskCevap = new TopluHacizHazirlaTaskCevap(dosya, task.data);
            await dosya.borclularQuery();
            let hacizParams = task.data.hacizParams;
            if (Object.keys(hacizParams).length > 0) {
                for (const borclu of dosya.borclular) {
                    console.log("BORCLU ", borclu);
                    for (const param of hacizParams) {
                        try {
                            if (param.talepKodu == UyapHacizEvrakTuru.egm) {
                                await borclu.queryEgm(dosya.dosyaId);
                                await borclu.queryEgmMahrumiyet(dosya.dosyaId);
                            }
                            if (param.talepKodu == UyapHacizEvrakTuru.takbis)
                                await borclu.queryBorcluTakbis(dosya.dosyaId);
                                //await borclu.queryTasinmazHisse(dosya.dosyaId);
                                //await borclu.queryTasinmazTakyidat(dosya.dosyaId);
                            if (param.talepKodu == UyapHacizEvrakTuru.banka_haczi)
                                await borclu.queryBorcluBankaBilgileri(dosya.dosyaId);
                            if (param.talepKodu == UyapHacizEvrakTuru.posta_ceki_haciz)
                                await borclu.queryBorcluPostaCeki(dosya.dosyaId);
                            if (param.talepKodu == UyapHacizEvrakTuru.maas_haczi) {
                                await borclu.queryBorcluSsk(dosya.dosyaId);
                                await borclu.queryBorcluSgkBagkur(dosya.dosyaId);
                                await borclu.queryBorcluSgkKamu(dosya.dosyaId);
                                await borclu.queryBorcluEmekliSsk(dosya.dosyaId);
                                await borclu.queryBorcluEmekliBagkur(dosya.dosyaId);
                                await borclu.queryBorcluEmekliKamu(dosya.dosyaId);
                                await borclu.queryBorcluSgkIsyeri(dosya.dosyaId);
                                await borclu.queryBorcluSgkHaciz(dosya.dosyaId);
                            }
                            if (param.talepKodu == UyapHacizEvrakTuru.alacakli_oldugu_dosya)
                                await borclu.queryBorcluIcraDosyaKaydi(dosya.dosyaId);
                        } catch (e) {
                            console.log("HATA", e);
                            if (e.message == "Bu işlemi bu dosya için gerçekleştiremezsiniz. Dosyada ALACAKLI vekili değilsiniz. ")
                                throw new Error(e.message);
                        }
                    }
                }
            }
            response.params = task.data;
            response.borclular = dosya.borclular;
            response.dosya = dosya;
            task.response = response;
            return task;
        } catch (e) {
            console.log('Error', e);
            throw new Error(e.message)
        }
    }
}